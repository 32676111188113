import axios from 'axios';

interface logBody {
  url: string,
  uniq_id: string,
  log_type: string,
  note?: string,
};

export const recordGenericAccessLog = async (params: { logType: string, note?: string }) => {

  let url = window.location.href
  let uniqId = new URLSearchParams(window.location.search).get('u') || '';

  let body: logBody = {
    "url": url,
    "uniq_id": uniqId,
    "log_type": params.logType,
  };

  if (params.note) {
    body["note"] = params.note;
  }

  let res = await axios.post(process.env.REACT_APP_API_URL + '/api/log', body)
  console.log(res.data);

};

interface InvokeUniqIdToEmailBody {
  uniq_id: string,
};

export const invokeUniqIdToEmail = async (params: { uniqId: string }) => {

  let body: InvokeUniqIdToEmailBody = {
    "uniq_id": params.uniqId,
  };

  let res = await axios.post(process.env.REACT_APP_API_URL + '/api/uid_to_email', body)
  console.log(res.data);
  return res.data.email;

};

interface InvokeUniqIdExistenceBody { 
  uniq_id: string;
}

interface UidExistenceResponse {
  has_uniq_id: boolean;
}

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:7000";

export const invokeUniqIdExistence = async (params: { uniqId: string }): Promise<boolean> => {
// メール本文のURLにアクセスした際に、URLに記載されたuniq_idが存在するかどうかをチェックし、boolean型で返す。
  try {
    let body: InvokeUniqIdExistenceBody = {
        "uniq_id": params.uniqId,
      };

    let res = await axios.post<UidExistenceResponse>(`${API_URL}/api/uid_existence_req`, body);
    console.log(res.data);
    return res.data.has_uniq_id;
  } catch (error) {
    console.error("API call failed:", error);
    return false;
  }
};

export const sleep = (ms: number) => new Promise(res => setTimeout(res, ms));