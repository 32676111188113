import { memo, useEffect, useState, useRef } from 'react';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";    
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { invokeUniqIdExistence, recordGenericAccessLog, sleep } from "../utils";

import rawCss from './FakeMyNumHealth.raw.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import transparentHeader from "../imgs/transparent-header.png";
import target_attack_mail_img from "../imgs/target_attack_mail_img.jpg";

const MenuBar = () => {
    const menuItems = [
        "テーマ別に探す",
        "健康・医療",
        "福祉・介護",
        "雇用・労働",
        "年金",
        "他分野の取り組み",
    ];

    return (
        <nav
            style={{
                backgroundColor: "#0650cb",
                padding: "10px 0",
                display: "flex",
                justifyContent: "start", // 左揃え
            }}
            className="menu-bar"
        >
            <ul
                style={{
                    display: "flex",
                    listStyleType: "none",
                    margin: 0,
                    padding: 0,
                }}
            >
                {menuItems.map((item, index) => (
                    <li
                        key={index}
                        style={{
                            color: "white",
                            margin: "0 10px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {item}
                        {index < menuItems.length - 1 && (
                            <span style={{ color: "white", margin: "0 0px 0 20px" }}>|</span>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

const FakeForm = memo(() => {
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastNameKana, setLastNameKana] = useState('');
    const [firstNameKana, setFirstNameKana] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [myNumber, setMyNumber] = useState('');
    const [descriptionVisible, setDescriptionVisible] = useState(false)
    const toast = useRef<Toast>(null);
    
    let initialized = false;
    const initialize = () => {
      if (!initialized) {
        (async () => {
          try {
            await recordGenericAccessLog({ logType: 'fake_myNumHealth_page_access' });
          } catch (ex) {
            toast.current?.show({ closable: false, severity: 'error', detail: '通信に失敗しました' });
          }
        })();
        initialized = true;
      }
    }

    const validateAndNormalizeBirthDay = (date: string): string | null => {

        if (/^(\d{8})$/.test(date)) {
            const year = date.substring(0, 4);
            const month = date.substring(4, 6);
            const day = date.substring(6, 8);
    
            return `${year}-${month}-${day}`;
        }
        return null;
    };

    const onClickConfirmButton = () => {
      (async () => {
        if (!lastName) {
          toast.current?.show({ closable: false, severity: 'warn', detail: '姓を入力してください' });
            await sleep(1);
            return;
        }
        if (!firstName) {
            toast.current?.show({ closable: false, severity: 'warn', detail: '名を入力してください' });
            await sleep(1);
            return;
        }
        if (!lastNameKana) {
            toast.current?.show({ closable: false, severity: 'warn', detail: 'セイを入力してください' });
            await sleep(1);
            return;
        }
        if (!firstNameKana) {
            toast.current?.show({ closable: false, severity: 'warn', detail: 'メイを入力してください' });
            await sleep(1);
            return;
        }
        if (!birthDay) {
            toast.current?.show({ closable: false, severity: 'warn', detail: '生年月日を西暦から数字8桁（例：19870123）で入力してください' });
            await sleep(1);
            return;
        }
    

        setDescriptionVisible(true);
        try {
            await recordGenericAccessLog({ logType: 'fake_myNumHealth_submit', note: `${lastName}:${firstName}:${lastNameKana}:${firstNameKana}:${birthDay}:${myNumber.length}` });
            } catch (ex) {
            console.log(ex);
            toast.current?.show({ closable: false, severity: 'warn', detail: 'ログインに失敗しました' });
        }
        
      })();
    }
  
  
  
    useEffect(() => {
      initialize();
      // eslint-disable-next-line
    }, []);
  
    return (
      <>
        <style>{rawCss}</style>

  
        <Toast ref={toast} />

        <Panel header="登録確認" className="loginPanel">
        <table className='m-table'>
            <tr>
                <th>事業者名</th>
                <td align='left'>株式会社グッドウイン</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th>法人番号</th>
                <td align='left'>6011001029864</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th>事業者番号</th>
                <td align='left'>T6011001029864</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
            <th>姓</th>
            <td align='left'><InputText value={lastName} onChange={(e) => setLastName(e.target.value)} /></td>
            <th>名</th>
            <td align='left'><InputText value={firstName} onChange={(e) => setFirstName(e.target.value)} /></td>
            </tr>
            <tr>
            <th>セイ</th>
            <td align='left'><InputText value={lastNameKana} onChange={(e) => setLastNameKana(e.target.value)} /></td>
            <th>メイ</th>
            <td align='left'><InputText value={firstNameKana} onChange={(e) => setFirstNameKana(e.target.value)} /></td>
            </tr>
            <tr>
                <th>生年月日</th>
                <td align='left'>
                    <InputText
                        value={birthDay}
                        onChange={(e) => setBirthDay(e.target.value)}
                        onBlur={() => {
                            const normalizedDate = validateAndNormalizeBirthDay(birthDay);
                            if (normalizedDate) {
                                setBirthDay(normalizedDate);
                            } else {
                                toast.current?.show({
                                    closable: false,
                                    severity: 'warn',
                                    detail: '生年月日は数字8桁（例：19870123）で入力してください',
                                });
                            }
                        }}
                        placeholder="YYYYMMDD"
                    />
                </td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th></th>
                <td colSpan={3} style={{ fontSize: "0.8em", textAlign: "left", verticalAlign: "top" }} >
                ※ 生年月日は数字8ケタ（例:19870123）で入力してください。
                </td>
            </tr>
            <tr>
                <th>マイナンバー</th>
                <td align='left'><InputText value={myNumber} onChange={(e) => setMyNumber(e.target.value)} 
                                placeholder='123456789012'/></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <th></th>
                <td colSpan={3} style={{ fontSize: "0.8em", textAlign: "left", verticalAlign: "top" }} >
                ※ 事業者名が表示されている場合、マイナンバーの入力は任意です。
                </td>
            </tr>
            <tr>
                <th></th>
                <td align='left'><Button onClick={onClickConfirmButton}>確認</Button></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                
                <td colSpan={4} align='left'>
                    {descriptionVisible &&
                        <div className="alertNote">
                        <h2>このページはセキュリティ研修の為のものです</h2>
                        <p>URLをクリックしてしまった場合、誘導先のウェブサイトを開くことにより悪意のあるプログラムが実行されるところから始まり、<br />
最終的にはGoodwin Navi全体がランサムウェアの被害に遭う事態になる可能性もあります。<br />
その場合、業務が停止するだけでなく、100万件を超える証券情報を含む個人情報等が漏えいし、甚大な被害が発生することになります。
                        </p><p>
                        標的型メールかどうかを区別するポイントを下記に挙げます。<br />
                        <img
                                    src={target_attack_mail_img}
                                    alt=""
                                />
                        </p>
                        <p>詳細は後日ご案内いたしますので、この訓練の実施について口外しないようにお願いします。<br />
                        <b>なお、入力したマイナンバーは、入力した形跡は記録されますが、番号は記録されませんのでご安心ください。</b></p>
                        </div>
                    }

                    {!descriptionVisible &&
                        <>
                        <div className="warningNote">
                            正確に入力してください。<br />
                            誤った入力が続くと、しばらくの間、登録状況が確認できなくなります。
                        </div>
                        </>
                    }
                </td>
            </tr>
        </table>
        </Panel>
      </>
    );
})

export const FakeMyNumHealth = () => {
    const [uniqIdExists, setUniqIdExists] = useState<boolean | null>(null); // 初期値を null に設定
    const [showContent, setShowContent] = useState(false); // 遅延表示用

    useEffect(() => {
        const uniqId = new URLSearchParams(window.location.search).get("u") || "";
        
        if (uniqId) {
            (async () => {
            try {
                const exists = await invokeUniqIdExistence({ uniqId });
                console.log("API response for uniqIdExists:", exists); // デバッグ用ログ
                setUniqIdExists(exists); // APIの結果に基づきステートを更新
            } catch (error) {
                console.error("Error checking uniqId existence:", error);
            }
            })();
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => setShowContent(true), 800); // 0.8秒後に表示を切り替え
        return () => clearTimeout(timer); // クリーンアップ
    }, []);
      
    return (
        <>
            <style>{rawCss}</style>
        {/* 条件付きで表示 */}
        {!uniqIdExists && (showContent && uniqIdExists === null || uniqIdExists === false) && 
        <h1 className="m-hdgLv1__hdg" style = {{color: "red", textAlign: "center"}}>このページは株式会社グッドウイン<br />社員セキュリティ研修
        の為のものです</h1>
        } 

            <div className="l-header">
                <div className="l-headerMain">
                    <div className="l-headerMain__inner">
                        <div className="l-headerMain__left">
                            <div className="m-headerLogo">
                                <img src="https://www.mhlw.go.jp/content/000269499.png" alt="厚生労働省" />
                                <img
                                    src={transparentHeader}
                                    alt=""
                                    className="transparent"
                                />
                            </div>
                            <div className="m-headerHome">
                                <span><FontAwesomeIcon icon={faHome} /> ホーム</span>
                            </div>
                            <button type="button" className="m-headerHmenu js-hmenu">
                                <span className="m-headerHmenu__line">
                                    <span>メニュー</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="l-content" id="content">
                <div className="l-contentHead">
                    <div className="l-contentHead__inner">
                        {/* メニュー */}
                        <MenuBar />

                        {/* パンくずリスト */}                        
                        <ol
                            className="m-navBreadcrumb"
                            itemScope
                            itemType="http://schema.org/BreadcrumbList"
                        >
                            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                <a href="" itemProp="item">
                                    <span itemProp="name">ホーム</span>
                                </a>
                                <meta itemProp="position" content="1" />
                                <span>&gt;</span>
                            </li>
                            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                <a href="" itemProp="item">
                                    <span itemProp="name">政策について</span>
                                </a>
                                <meta itemProp="position" content="2" />
                                <span>&gt;</span>
                            </li>
                            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                <a href="" itemProp="item">
                                    <span itemProp="name">分野別の政策一覧</span>
                                </a>
                                <meta itemProp="position" content="3" />
                                <span>&gt;</span>
                            </li>
                            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                <a href="" itemProp="item">
                                    <span itemProp="name">健康・医療</span>
                                </a>
                                <meta itemProp="position" content="4" />
                                <span>&gt;</span>
                            </li>
                            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                <a href="" itemProp="item">
                                    <span itemProp="name">医療保険</span>
                                </a>
                                <meta itemProp="position" content="5" />
                                <span>&gt;</span>
                            </li>
                            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                                <span itemProp="name" aria-current="page">
                                    マイナンバー健康保険証 登録状況確認
                                </span>
                                <meta itemProp="position" content="6" />
                            </li>

                        </ol>
                    </div>
                </div>
            </div>

            <div className="main#l-content">
                <div className="l-contentBody">
                    <div className="l-contentBody__inner">
                        <div className="l-contentMain">
                            <div className="m-hdgLv1">
                                <h1 className="m-hdgLv1__hdg">
                                    マイナンバー健康保険証 登録状況確認
                                </h1>
                                <p></p>
                                <FakeForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
